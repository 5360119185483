import React, { useState, useEffect, useContext } from "react";
import { Button, Modal, Select, Form } from 'antd';
import { Row, Col } from "react-bootstrap";
import konsole from "../../network/konsole";
import Services from "../../network/Services";
import { UserContext } from "../../App";
import { EventBaseUrl, CounterBaseUrl, CounterMobileBaseUrl, AoAdminBaseUrl, AoPartnerBaseUrl, AoAcaddemyUrl, IntakeEntryBaseUrl, lpoiceLink, AoAgenturl, intakeBaseUrl, lpoLiteUrl } from "../../network/url";

const { Option } = Select;

const AccountRoleChange = () => {
    const [selectedRoleId, setSelectedRoleId] = useState('');
    const [form] = Form.useForm();
    const globalData = useContext(UserContext)
    const [isAccountRoleChange, setIsAccountRoleChange] = useState(false)
    const { loggedInUserRoles, setLoggedInUserRoles, setLoader } = globalData;
    const [isErrorSomethinWrong, setIsErrorSomethingWrong] = useState(false)

    console.log("loggedInUserRoles", loggedInUserRoles)
    useEffect(() => {
        if (loggedInUserRoles?.length == 0) {
            fetchRole();
        }
    }, []);

    const fetchRole = () => {
        let userId = sessionStorage.getItem('loggedUserId');
        let roleUserId = sessionStorage.getItem('roleUserId');
        setLoader(true);
        Services.getUserDetailsByUserEmailId({ userId })
            .then((res) => {
                konsole.log("response", res);
                setLoader(false);
                if (res?.data?.length > 0) {
                    const resData = res.data[0];
                    const userRoles = resData?.roleDetails?.filter((item) => item.roleId != roleUserId && item?.roleId != 1 && item?.roleId != 2 && item?.roleId != 20 & item.roleId != 9 && item.roleId != 7 && item.roleId != 8 && item.roleId != 12 && item.roleId != 19 && item.roleId != 17 && item.roleId != 18)?.map((i) => ({ ...i, value: i.roleId, label: i.roleName }));
                    konsole.log("userRoles", userRoles);
                    const sortOrder = [3, 13, 14, 15, 4, 11, 6, 16];
                    const sortedRoles = [...userRoles]?.sort((a, b) => {
                        const indexA = sortOrder?.indexOf(Number(a.roleId));
                        const indexB = sortOrder?.indexOf(Number(b.roleId));
                
                        if (indexA === -1 && indexB === -1) return 0;
                        if (indexA === -1) return 1;
                        if (indexB === -1) return -1;
                        return indexA - indexB;
                    });
                    setLoggedInUserRoles(sortedRoles);
                }
            })
            .catch((err) => {
                setLoader(false);
                konsole.log("Error", err);
            });
    };
    const handleChange = (e) => {
        setIsErrorSomethingWrong(false)
        setSelectedRoleId(e);
    };
    function getRoleLabel(roleId) {
        const urlMap = {
            '1': intakeBaseUrl + 'login',
            '3': intakeBaseUrl,
            '4': AoAdminBaseUrl,
            '5': AoAgenturl,
            '6': CounterBaseUrl,
            '8': CounterBaseUrl,
            '10': intakeBaseUrl,
            '11': AoAdminBaseUrl,
            '13': intakeBaseUrl,
            '14': intakeBaseUrl,
            '15': intakeBaseUrl,
            '16': CounterMobileBaseUrl,
            '21': IntakeEntryBaseUrl
        };
        return urlMap[roleId] || '';
    }

    const handleSubmit = () => {
        form
            .validateFields()
            .then((values) => {
                konsole.log('Selected Role ID:', selectedRoleId);
                konsole.log("valuesvalues", values)
                let roleId = values?.roleId;
                let stateObj = JSON.parse(sessionStorage.getItem('stateObj'));
                let loggedInUserId = sessionStorage.getItem('loggedUserId');
                konsole.log("stateObj", stateObj, stateObj?.appState)
                let tokenKey = `appState=${stateObj?.appState}&userId=${loggedInUserId}&roleId=${roleId}&loggenInId=${stateObj?.loggenInId}`;
                console.log("tokenKey", tokenKey)
                let roleUrl = getRoleLabel(roleId);
                if (!(roleUrl != undefined && roleUrl != null && roleUrl != '')) {
                    setIsErrorSomethingWrong(true);
                    return;
                }
                konsole.log("roleUrl", roleUrl);
                let redirectUrl = `${roleUrl}?token=${window.btoa(tokenKey)}`
                // window.open(redirectUrl, "_blank");
                sessionStorage.clear();
                window.location.replace(redirectUrl);

            })
            .catch((errorInfo) => {
                konsole.log('Validation Failed:', errorInfo);
            });
    };

    konsole.log("loginMemberRoles", loggedInUserRoles);
    konsole.log("globalData", globalData);

    const handleAccountRoleChange = (val) => {
        setIsAccountRoleChange(val)
    }
    return (
        <>
            {loggedInUserRoles?.length > 0 && <>
                <span className="ms-4">
                    <Button onClick={() => handleAccountRoleChange(true)} style={{ "background-color": "#720C20", color: "white", borderRadius: "5px" }} >
                        Switch Account
                    </Button>
                </span>
                <div className="w-50">
                    <Modal
                        className="Account-Role-Change"
                        visible={isAccountRoleChange}
                        title={<div className="d-flex justify-content-center">Account</div>}
                        onCancel={() => handleAccountRoleChange(false)}
                        footer={null}
                        closable={true}
                        closeIcon={<div className="text-light">  <img src="/whiteCrossIcon.svg" className='cursor-pointer'/></div>}
                        centered={true}
                        maskClosable={false}
                    >
                        <div className="mt-2 mb-4">
                            <Form form={form}>
                                <Row>
                                    <Col className="d-flex justify-content-center">

                                        <div>
                                            {(isErrorSomethinWrong == true) && <div className="text-danger mb-2" >Something went wrong.Please try again after some time. </div>}
                                            <div className="fw-bold">Please pick a role for this visit</div>
                                            <Form.Item
                                                name="roleId"
                                                rules={[{ required: true, message: "Please select a role" }]}
                                                style={{ marginTop: "2px" }}
                                            >
                                                <Select
                                                    name="roleId"
                                                    placeholder="Select"
                                                    style={{ width: 300 }}
                                                    onChange={handleChange}
                                                    value={selectedRoleId}
                                                >
                                                    {loggedInUserRoles.map((item, index) => (
                                                        <Option value={item.value} key={item.label}>
                                                            {item.label}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                            <div className="d-flex justify-content-center">
                                                <Button onClick={handleSubmit} style={{ "background-color": "#720C20", color: "white", borderRadius: "5px", marginRight: "10px" }} >
                                                    Continue
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Modal>
                </div>
            </>}
        </>
    );
};

export default AccountRoleChange;
